import axios from 'axios'

import type { CommonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'

export type ReportAverageResponse = {
  data: ReportAverage[]
}

export type ReportAverage = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  hourlyAvgProductivity: number | null
  dailyAvgPlanAccuracy: number | null
}

export type ReportProductivityResponse = {
  workspaceId: number
  workspaceName: string
  groups: ProductivityGroup[]
  dailyWorkData: DailyWork[]
}

export type ProductivityGroup = {
  groupId: number | null
  groupName: string | null
  supportedWorkspaceId: number | null
  supportedWorkspaceName: string | null
  workers: ProductivityWorker[]
}

export type ProductivityWorker = {
  workerId: number
  workerName: string
  workerWmsMemberId: string
  dailyWorkData: DailyWork[]
}

export type DailyWork = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  hourlyAvgProductivity: number | null
  data: DailyWorkRow[]
}

export type DailyWorkRow = {
  date: string
  dailyAvgPlanCount: number | null
  dailyAvgRecordCount: number | null
}

export type ReportPlanAccuracyResponse = {
  data: PlanAccuracy[]
}

export type PlanAccuracy = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  data: PlanAccuracyRow[]
}

export type PlanAccuracyRow = {
  date: string
  archivePlanCount: number | null
  lastPlanCount: number | null
}

export const ExportDataTypes = {
  PlanAndRecord: 'planAndRecord',
  PlanDetail: 'planDetail',
} as const

export type ExportDataType = (typeof ExportDataTypes)[keyof typeof ExportDataTypes]

export type CreateExportDataInfo = {
  startDate: string
  endDate: string
  targetWorkspaces: number[]
  includeProductivityValue: boolean
  includeRecord?: boolean
  exportDataType: ExportDataType
}

export type CreateExportDataResponse = {
  requestId: string
}

export type CheckExportDataReadyResponse = {
  downloadUrl: string
}

export const getReportAverage = (
  params: CommonParams,
  workspaceId: number,
  from: string,
  to: string
): Promise<ReportAverageResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/report-average`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportProductivity = (
  params: CommonParams,
  workspaceId: number,
  from: string,
  to: string
): Promise<ReportProductivityResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/report-productivity`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportPlanAccuracy = (
  params: CommonParams,
  workspaceId: number,
  from: string,
  to: string
): Promise<ReportPlanAccuracyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/report-plan-accuracy`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { from, to } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getReportHourlyPlanAccuracy = (
  params: CommonParams,
  workspaceId: number,
  date: string
): Promise<ReportPlanAccuracyResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/workspaces/${workspaceId}/work-date/${date}/report-hourly-plan-accuracy`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createExportData = (params: CommonParams, data: CreateExportDataInfo): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/data-export/async`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getExportData = (params: CommonParams, requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/data-export/update-status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
