import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import * as API from 'api/dashboard'
import { UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type ScheduleTypeSummaryData = API.ScheduleTypeSummaryResponse & { scheduleTypeId: number }

type DashboardState = {
  isRequesting: boolean
  errorMessage: string
  tenantSummary?: API.TenantSummaryResponse
  workspaceSummary?: API.WorkspaceSummaryResponse
  bopMonitoring?: API.TenantBopMonitoringResponse
  workerCounts: API.WorkerCountWorkspaceData[]
  scheduleTypeSummary: ScheduleTypeSummaryData[]
}

const initialState: DashboardState = {
  isRequesting: false,
  errorMessage: '',
  workerCounts: [],
  scheduleTypeSummary: [],
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },

    getTenantSummarySuccess: (state, action: PayloadAction<API.TenantSummaryResponse>) => {
      state.isRequesting = false
      if (!state.tenantSummary) {
        state.tenantSummary = action.payload
      }
      const hourlyWorkData = action.payload.hourlyWorkData.reduce((acc, cur) => {
        if (acc.some(hwd => hwd.scheduleTypeId === cur.scheduleTypeId)) {
          return acc.map(data => (data.scheduleTypeId === cur.scheduleTypeId ? cur : data))
        }
        acc.push(cur)
        return acc
      }, state.tenantSummary.hourlyWorkData)

      // ワークスペースの編集によりデータ変更された場合にstate.tenantSummary.workspaceDataを更新するための変数
      const updateWorkspaceData = action.payload.workspaceData.map(wd => {
        const target = state.tenantSummary?.workspaceData.find(w => w.workspaceId === wd.workspaceId)
        if (!target) {
          return wd
        }

        const data = wd.data.map(d => {
          const targetData = target.data.find(td => td.scheduleTypeId === d.scheduleTypeId)
          if (!targetData) {
            return d
          }
          return { ...targetData, scheduleTypeName: d.scheduleTypeName }
        })
        return { data, workspaceId: wd.workspaceId, workspaceName: wd.workspaceName }
      })

      const updateScheduleTypeIds = action.payload.hourlyWorkData.map(hwd => hwd.scheduleTypeId)
      const workspaceData = updateWorkspaceData.map(wd => {
        const data = wd.data.map(d =>
          updateScheduleTypeIds.includes(d.scheduleTypeId)
            ? action.payload.workspaceData.flatMap(w => w.data).find(w => w.scheduleTypeId === d.scheduleTypeId) || d
            : d
        )
        return { ...wd, data }
      })
      state.tenantSummary = { hourlyWorkData, workspaceData }
    },
    getWorkspaceSummarySuccess: (state, action: PayloadAction<API.WorkspaceSummaryResponse>) => {
      state.isRequesting = false
      state.workspaceSummary = action.payload
    },

    clearTenantSummary: state => (state.tenantSummary = undefined),
    getBopMonitoringSummarySuccess: (state, action: PayloadAction<API.TenantBopMonitoringResponse>) => {
      state.isRequesting = false
      state.bopMonitoring = action.payload
    },
    getWorkerCountsSuccess: (state, action: PayloadAction<API.WorkerCountsResponse>) => {
      state.isRequesting = false
      state.workerCounts = _.sortBy(action.payload.workspaceData, 'workspaceName').map(workspace => ({
        ...workspace,
        planSummary: _.sortBy(workspace.planSummary, 'scheduleTypeName'),
      }))
    },
    getScheduleTypeSummarySuccess: (state, action: PayloadAction<ScheduleTypeSummaryData>) => {
      state.isRequesting = false
      const index = state.scheduleTypeSummary.findIndex(s => s.scheduleTypeId === action.payload.scheduleTypeId)
      index >= 0
        ? state.scheduleTypeSummary.splice(index, 1, action.payload)
        : state.scheduleTypeSummary.push(action.payload)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getTenantSummarySuccess,
  getWorkspaceSummarySuccess,
  clearTenantSummary,
  getBopMonitoringSummarySuccess,
  getWorkerCountsSuccess,
  getScheduleTypeSummarySuccess,
} = dashboardSlice.actions

export const getTenantSummary =
  (date: string, filteringData: API.TenantSummaryFilteringData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenantSummary(commonParams(getState), date, filteringData)
      .then((res: API.TenantSummaryResponse) => dispatch(getTenantSummarySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkspaceSummary =
  (workspaceId: number, date: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkspaceSummary(commonParams(getState), workspaceId, date)
      .then((res: API.WorkspaceSummaryResponse) => dispatch(getWorkspaceSummarySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopMonitoringSummary =
  (date: string, data: API.TenantBopMonitoringParam): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopMonitoring(commonParams(getState), date, data)
      .then((res: API.TenantBopMonitoringResponse) => dispatch(getBopMonitoringSummarySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkerCounts =
  (date: string, workspaceId?: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorkerCounts(commonParams(getState), date, workspaceId)
      .then((res: API.WorkerCountsResponse) => dispatch(getWorkerCountsSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getScheduleTypeSummary =
  (workspaceId: number, date: string, scheduleTypeId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getScheduleTypeSummary(commonParams(getState), workspaceId, date, scheduleTypeId)
      .then((res: API.ScheduleTypeSummaryResponse) =>
        dispatch(getScheduleTypeSummarySuccess({ ...res, scheduleTypeId }))
      )
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectDashboardStatus = (state: RootState) => ({ ...state.dashboard })

export default dashboardSlice.reducer
