import { chain } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Col, Input, FormGroup, Label } from 'reactstrap'

import type { PlanType, V2AttendanceType } from 'api/optimization'

import { getTenantSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { createDataAt, selectOptimizationStatus, clearError, getLocationConfig } from 'slices/optimizationSlice'
import { selectPlansStatus } from 'slices/plansSlice'

import { BadgeButton, CustomModal, SelectBoxFormat, TimeSelect } from 'components/common'
import type { BadgeItem } from 'components/common/types'
import { SHIFT_SCHEDULE_TYPE_ID, COLORS, roundedMoment, ColumnSizes } from 'components/common/utils'

import usePlans from 'hooks/usePlans'

import type { EditGroupsType, WorkPlanSchedulesType } from '../types'

const SELECT_DATA = {
  ALL: 'all',
  SELECT: 'select',
}

const groupItems = [
  { key: SELECT_DATA.ALL, value: 'すべてのメンバー' },
  { key: SELECT_DATA.SELECT, value: '特定のメンバー' },
]
const initialInputData: SelectDataType = { hour: '09', minute: '00', work: SELECT_DATA.ALL, group: SELECT_DATA.ALL }

type HourlyTimeRecordData = {
  scheduleTypeId: number
  planValue: number | null
  actualValue: number | null
  ratio: number
}
type SelectDataType = {
  hour: string
  minute: string
  work?: string
  group?: string
}
type Props = {
  apiKey: string
  magiQannealTenant: string
  magiQannealLocation: string
  isOpen: boolean
  workspaceId: number
  editGroups: EditGroupsType[]
  onCancel: () => void
  workDate?: string
}

export const GROUP_BLANK_ID = -1

export const OptimizationDialog: React.FC<Props> = props => {
  const { apiKey, magiQannealTenant, magiQannealLocation, isOpen, editGroups, onCancel, workDate } = props

  const dispatch = useDispatch()
  const { data, optimizationError, isRequesting } = useSelector(selectOptimizationStatus, shallowEqual)
  const { plans } = useSelector(selectPlansStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectDashboardStatus, shallowEqual)

  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [inputData, setInputData] = React.useState<SelectDataType>(initialInputData)
  const [selectedGroups, setSelectedGroups] = React.useState<number[]>([])
  const [submit, setSubmit] = React.useState(false)
  const [priority, setPriority] = React.useState(true)

  const { planWorkDate, dailyTarget } = usePlans()

  React.useEffect(() => {
    if (!planWorkDate || !isOpen) {
      return
    }
    dispatch(getTenantSummary(planWorkDate, { dashboardFilter: false }))
  }, [dispatch, planWorkDate, isOpen])

  const targetWorks = React.useMemo(() => data?.works.map(w => w.workId) ?? [], [data])

  const approveDisabled = React.useMemo(
    () => !plans || targetWorks.length === 0 || selectedGroups.length === 0,
    [plans, targetWorks.length, selectedGroups.length]
  )

  const groupBadgeItems = React.useMemo<BadgeItem[]>(
    () =>
      plans?.groups.map(group => ({
        color: COLORS[0],
        key: group.groupId ?? GROUP_BLANK_ID,
        label: group.groupName ?? '未所属', // group.name が null の場合には未所属と表示する
      })) || [],
    [plans?.groups]
  )

  const planned = React.useMemo<PlanType[]>(
    () =>
      targetWorks.map<PlanType>(targetWorkId => {
        const targetValue = dailyTarget?.data.find(t => t.scheduleTypeId === targetWorkId)?.target

        return {
          workId: targetWorkId,
          quota: targetValue ?? 0,
        }
      }),
    [dailyTarget, targetWorks]
  )

  const processed = React.useMemo<PlanType[]>(() => {
    const body = targetWorks.map<PlanType>(targetWorkId => ({ workId: targetWorkId, quota: 0 }))
    if (!tenantSummary) {
      return body
    }

    return tenantSummary.workspaceData
      .flatMap(scheduleTypeData => scheduleTypeData.data)
      .reduce((acc, cur) => {
        const target = acc.find(a => a.workId === cur.scheduleTypeId)
        if (target) {
          target.quota += cur.recordCount ?? 0
        }
        return acc
      }, body)
  }, [targetWorks, tenantSummary])

  const predicted = React.useMemo<PlanType[]>(() => {
    const body = targetWorks.map<PlanType>(targetWorkId => ({ workId: targetWorkId, quota: 0 }))
    if (!tenantSummary) {
      return body
    }
    // predicted の集計に使う終了時刻(inputData.minute が 15, 30, 45 だったときのために 1 時間分多く集めておく)
    const lastHourStart = new Date(`${planWorkDate} ${inputData.hour}:00:00`)

    return (
      tenantSummary.hourlyWorkData
        // inputData.minute が 15, 30, 45 の場合には inputData.minute/60 だけ planValue を加えるために ratio を作る
        .flatMap<HourlyTimeRecordData>(rec =>
          rec.data.map(d => {
            const time = new Date(d.time)
            if (time < lastHourStart) {
              return {
                scheduleTypeId: rec.scheduleTypeId,
                planValue: d.planCount,
                actualValue: d.recordCount,
                ratio: 1,
              }
            }
            if (lastHourStart < time) {
              return {
                scheduleTypeId: rec.scheduleTypeId,
                planValue: d.planCount,
                actualValue: d.recordCount,
                ratio: 0,
              }
            }
            return {
              scheduleTypeId: rec.scheduleTypeId,
              planValue: d.planCount,
              actualValue: d.recordCount,
              ratio: Number(inputData.minute) / 60,
            }
          })
        )
        .reduce((acc, cur) => {
          const target = acc.find(a => a.workId === cur.scheduleTypeId)
          if (target) {
            target.quota += Math.round((cur.planValue ?? 0) * cur.ratio)
          }
          return acc
        }, body)
    )
  }, [targetWorks, tenantSummary, planWorkDate, inputData.hour, inputData.minute])

  const getWorkingSlotsSchedule = React.useCallback(
    (schedules: WorkPlanSchedulesType[]) => {
      return chain(schedules)
        .sortBy('scheduleTypeId')
        .reduce(
          (acc, cur) => {
            const isShift = cur.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
            const isSelectedWork = targetWorks.includes(cur.scheduleTypeId)

            // 優先にチェックがない場合かつ、シフト作業ではない場合はそのまま返す
            if (!priority && !isShift) {
              return acc
            }

            const baseDate = moment(cur.startAt).startOf('day')
            const start = moment(cur.startAt)
            const end = moment(cur.startAt).add(cur.duration, 'seconds')
            // シフト作業と最適配置対象作業は'1',それ以外は'0'
            return [...Array(24 * 4)]
              .map((_, index) => {
                const isActiveTime = baseDate.add(15, 'minutes').isBetween(start, end, 'minutes', '(]')
                if (isActiveTime && (isSelectedWork || isShift)) {
                  return '1'
                }
                return isActiveTime ? '0' : acc.charAt(index)
              })
              .join('')
          },
          ''.padStart(96, '0')
        )
        .value()
    },
    [priority, targetWorks]
  )

  const attendances = React.useMemo<V2AttendanceType[]>(
    () =>
      editGroups
        .filter(group => group.groupId && selectedGroups.includes(group.groupId))
        .flatMap(group => group.workers)
        .map(workers => ({
          workerId: workers.wmsMemberId || '',
          schedule: getWorkingSlotsSchedule(workers.schedules),
        })) ?? [],
    [editGroups, getWorkingSlotsSchedule, selectedGroups]
  )

  const datetime = React.useMemo(() => {
    return moment(`${planWorkDate} ${inputData.hour}:${inputData.minute}`, 'YYYY-MM-DD HH:mm').format()
  }, [planWorkDate, inputData.hour, inputData.minute])
  const handleSubmitClick = () => {
    if (!plans) {
      return
    }
    setSubmit(true)
    dispatch(
      createDataAt(apiKey, magiQannealTenant, magiQannealLocation, datetime, planned, processed, predicted, attendances)
    )
  }

  const onGroupBadgeClick = (groupIds: number[]) => {
    setSelectedGroups(groupIds)
  }

  React.useEffect(() => {
    if (!isOpen || isRequesting || !submit) {
      return
    }
    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される
      dispatch(clearError())
    } else if (submit) {
      window.open(
        `${
          process.env.REACT_APP_OPTIMIZATION_SERVER
        }/${magiQannealTenant}/${magiQannealLocation}/board?datetime=${encodeURIComponent(datetime)}`,
        '_blank'
      )
    }
    setSubmit(false)
    onCancel()
  }, [
    dispatch,
    isOpen,
    isRequesting,
    onCancel,
    optimizationError,
    submit,
    datetime,
    magiQannealTenant,
    magiQannealLocation,
  ])

  React.useEffect(() => {
    if (!isOpen) {
      return
    }
    dispatch(
      getLocationConfig(apiKey, magiQannealTenant, magiQannealLocation, moment(workDate).startOf('day').format())
    )
    const now = roundedMoment()
    setInputData({ ...initialInputData, ...now })
    setPriority(true)
  }, [apiKey, magiQannealTenant, magiQannealLocation, dispatch, isOpen, workDate])

  React.useEffect(
    () => setSelectedGroups(inputData.group === SELECT_DATA.ALL ? groupBadgeItems.map(g => g.key) : []),
    [inputData.group, groupBadgeItems]
  )

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="最適配置設定"
      approveLabel="設定をmagiQannealと連携"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSubmitClick}
      approveDisabled={approveDisabled}
      onHideNotification={() => setModalErrorMessage(undefined)}
      overflow="visible"
    >
      <div className="mb-3">AI&amp;量子コンピュータが最適な配置を提案します。最適配置の設定を行ってください。</div>

      <FormGroup row>
        <Label md={4}>開始時間</Label>
        <Col md={8}>
          <TimeSelect
            hour={inputData.hour}
            minute={inputData.minute}
            label=""
            onChange={(hour, minute) => setInputData({ ...inputData, hour, minute })}
            menuZIndex={2} // customInputがzIndex:1のため、それ以上の値を設定
          />
        </Col>
      </FormGroup>

      <SelectBoxFormat
        label="最適配置対象メンバー"
        value={inputData.group}
        size={ColumnSizes.middle}
        items={groupItems}
        onChange={event => setInputData({ ...inputData, group: event.key?.toString() })}
        className="mb-3"
      />
      {inputData.group === SELECT_DATA.SELECT && (
        <>
          <div>最適配置対象としたいメンバーを選択してください。</div>
          <div className="d-flex row py-3 pe-2 me-0" style={{ marginLeft: '-0.5rem' }}>
            <BadgeButton items={groupBadgeItems} selected={selectedGroups} onChange={onGroupBadgeClick} hideColor />
          </div>
        </>
      )}
      <div className="form-check">
        <Input
          className="form-check-input"
          id="optimization-priority"
          checked={priority}
          type="checkbox"
          onChange={e => setPriority(e.target.checked)}
        />
        <Label className="form-check-label" for="optimization-priority">
          既に入力されている予定を優先する
        </Label>
      </div>
    </CustomModal>
  )
}
